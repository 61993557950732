module.exports = [{
      plugin: require('/Users/philiphardwick/Development/Repos/WickTechnology/wick-technology-site/site/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-139849067-1"},
    },{
      plugin: require('/Users/philiphardwick/Development/Repos/WickTechnology/wick-technology-site/site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/philiphardwick/Development/Repos/WickTechnology/wick-technology-site/site/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/philiphardwick/Development/Repos/WickTechnology/wick-technology-site/site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
